import React from "react"

import Section from "../layout/section"
import { FixedContainer } from "../layout/container"

import ExternalLink from "../layout/externalLink"

const IndexAbout = () => (
  <Section>
    <FixedContainer>
      <h3>Our Group</h3>
      <p>
        The University of Rochester 
        {` `}
        <b>Atmospheric Chemistry &amp; Climate Group</b>
        {` `}
        explores the nexus of atmospheric composition and climate.
        We develop and use state-of-the-science global 
        {` `}
        <ExternalLink path="http://www.geos-chem.org/" title="GEOS-Chem">air quality</ExternalLink>
        {` `}
        and
        {` `}
        <ExternalLink path="https://www.giss.nasa.gov/tools/modelE" title="GISS ModelE">climate</ExternalLink>
        {` `}
         models, employing both forward and inverse methods to interpret and assimilate various surface, 
         aircraft and satellite observations to further understanding of atmospheric processes and humanity's role in the Earth system.
      </p>
      <p>
        Our research is motivated by three grand challenges:
      </p>

    </FixedContainer>
  </Section>
)

export default IndexAbout