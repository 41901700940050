import React from "react"
import { useStaticQuery, graphql } from "gatsby"


import Section from "../layout/section"
import { FixedContainer } from "../layout/container"
import ExternalLink from "../layout/externalLink"
import { Topic } from "../research/topic"

const IndexTopics = () => {

  const data = useStaticQuery(graphql`{
  airQuality: file(relativePath: {eq: "smokestacks.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: BLURRED
      )
    }
  }
  climateChange: file(relativePath: {eq: "iceberg.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: BLURRED
      )
    }
  }
  bigData: file(relativePath: {eq: "server.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: BLURRED
      )
    }
  }
}
`)

  return (
    <Section>
      <FixedContainer>
        <div className="topics columns is-variable is-4">
          <Topic 
            title="Air Quality" 
            image={data.airQuality}
          >
            <p className="subtitle is-6">
              Air pollution is the
              {` `}
              <ExternalLink path="http://www.who.int/gho/phe/en/" title="WHO | World Health Organization">leading cause</ExternalLink>
              {` `}
              of preventable death world-wide.
            </p>
            <p>
              Science-driven policies have greatly
              {` `}
              <ExternalLink path="http://www.bbc.com/news/science-environment-35099519" title="BBC | Aura mission tracks global pollutant trends">improved</ExternalLink>
              {` `}
              air quality in certain locations, but furthur improvements are increasingly challenging. 
              Meanwhile, air pollution is deteriorating in developing countries. 
              Complicating everything is how air quality may change in a warming world. 
              We seek to minimize these uncertainties and inform public health.
            </p>
          </Topic>
          <Topic title="Climate Change" image={data.climateChange}>
            <p className="subtitle is-6">
              Human-caused climate change is one of the 
              {` `}
              <ExternalLink 
                path="https://www.un.org/en/climatechange" 
                title="United Nations"
              >
                greatest
              </ExternalLink>
              {` `}

              {` `}
              <ExternalLink 
                path="https://media.defense.gov/2019/Jan/29/2002084200/-1/-1/1/CLIMATE-CHANGE-REPORT-2019.PDF" 
                title="Report on Effects of a Changing Climate to the Department of Defense"
              >
                threats
              </ExternalLink>
              {` `}
              {` `}
              <ExternalLink 
                path="https://nca2018.globalchange.gov" 
                title="Fourth National Climate Assessment"
              >
                facing
              </ExternalLink>
              {` `}
              {` `}
              <ExternalLink 
                path="https://health2016.globalchange.gov" 
                title="The Impacts of Climate Change on Human Health in the United States"
              >
                humanity
              </ExternalLink>
              {` `}
              {` `}
              <ExternalLink 
                path="https://www.ipcc.ch/sr15/chapter/spm/" 
                title="IPCC Special Report: Global Warming of 1.5ºC"
              >
                today
              </ExternalLink>
              .
            </p>
            <p>
              Our group focuses on understanding the
              {` `}
              <ExternalLink path="http://www.ipcc.ch/report/ar6/wg1/" title="AR6 Climate Change 2021: The Physical Science Basis — IPCC">physical science basis</ExternalLink>
              {` `}
              of Earth&apos;s climate.
              How and why has Earth&apos;s climate naturally varied in the past? 
              In what ways are humans changing the present climate? 
              What will the future look like? 
              Above all, how does atmospheric chemistry influence climate change and vice versa?
            </p>
          </Topic>
          <Topic title="Big Data" image={data.bigData}>
            <p className="subtitle is-6">
              We live in an era of
              {` `}
              <ExternalLink path="https://en.wikipedia.org/wiki/Big_data" title="Big Data | Wikipedia">Big Data</ExternalLink>
              .
            </p>
            <p>
              Research is increasingly limited by lack of computational power rather than data availability.
              Atmospheric models helped
              {` `}
              <ExternalLink path="http://celebrating200years.noaa.gov/foundations/numerical_wx_pred/welcome.html" title="The History of Numerical Weather Prediction">motivate</ExternalLink>
              {` `}
              creation of the first computers, and have pushed their limits ever since. 
              We regularly need to generate, process and distill PB of data into fundamental knowledge.
              We seek to identify and apply new computational methods for efficiency and data mining.
            </p>
          </Topic>
        </div>
      </FixedContainer>
    </Section>
  )
}

export default IndexTopics