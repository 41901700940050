import React from "react"
import { Background } from "react-parallax"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import IndexAbout from "../components/index/about"
import IndexTopics from "../components/index/topics"
import IndexProspective from "../components/index/prospective"
import Video from "../components/index/video"

const IndexPage = () => {
  const parallax = {
    strength: 400,
    background: (
      <Background className="videoBg ">
        <Video />
      </Background>
    )
  }
  const headerProps = {
    preTitle: "University of Rochester",
    title: "Atmospheric Chemistry & Climate Group",
    subtitle: "Dr. Lee T. Murray",
    isFullHeight: true,
    parallax
  }

  return (
    <Layout headerProps={headerProps}>
      <SEO title="Home" />
      <IndexAbout />
      <IndexTopics />
      <IndexProspective />
    </Layout>
  )
}

export default IndexPage
