/* eslint-disable jsx-a11y/media-has-caption */
import React from "react"

import video720 from "../../videos/geos-chem720.mp4"
import poster from "../../images/geos-chem.jpg"

const Video = () => (
  <video loop playsInline preload="auto" autoPlay poster={poster}>
    <source src={video720} type="video/mp4" />
  </video>
)

export default Video