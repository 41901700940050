import React from "react"

import Section from "../layout/section"
import { FixedContainer } from "../layout/container"

import ExternalLink from "../layout/externalLink"

const IndexProspective = () => (
  <Section>
    <FixedContainer>
      <h3>Prospective Group Members</h3>
      <p>
        Our group admits students through the Ph.D. programs in
        {` `}
        <ExternalLink path="http://www.sas.rochester.edu/ees/graduate" title="Dept. of Earth and Environmental Sciences">Geosciences</ExternalLink>
        {` `}
        or
        {` `}
        <ExternalLink path="http://www.sas.rochester.edu/pas/graduate" title="Dept. of Physics and Astronomy">Physics and Astronomy</ExternalLink>
        . Research opportunities may exist for M.S. students in the
        {` `}
        <ExternalLink path="https://www.rochester.edu/data-science/" title="Goergen Institute for Data Science">Goergen Institute for Data Science</ExternalLink>
        {` `}
        and for undergraduate students interested in term-time or summer research. 
        Our research is highly interdisciplinary, so students from a wide variety of backgrounds will be considered. 
        Successful applicants will be highly self-motivated and have demonstrated aptitude in prior research experiences.
        Previous programming experience is preferred, but not required.
      </p>
      <p>
        Prospective students and postdoctoral researchers are encouraged to contact Dr. Murray via e-mail at
        {` `}
        <a href="mailto:lee.murray@rochester.edu">lee.murray@rochester.edu</a>
        {` `}
        with any questions. A list of presently available opportunities may be found on our
        {` `}
		<ExternalLink path="http://atmos.earth.rochester.edu/jobs/" title="Group Openings">Group Openings</ExternalLink>
		{` `}
		page.
      </p>
      <p>
        Our group is interested in promoting diversity in the Geosciences. 
        Applicants from underrepresented groups are especially encouraged to apply and explore the resources available through the UR
        {` `}
        <ExternalLink path="http://www.rochester.edu/college/kearnscenter/GraduatePrograms/GraduatePrograms.html" title="David T. Kearns Center">David T. Kearns Center</ExternalLink>
        .
      </p>
    </FixedContainer>
  </Section>
)

export default IndexProspective